import { MOBILE_VIEWPORT_WIDTH, SECONDARY_APP_PATH_PREFIX, SECONDARY_SUFFIX } from '@/constants'
import router from '@/router/index'
import store from '@/store'
import typedStore from '@/store/typedStore'
import {
  NodeStoryKeys,
  NodeTypes,
  Path,
  SplashScreenDisplays,
  StoryKeys,
  Tab,
  VisualisationType,
} from '@/types'
import { Location } from 'vue-router'
// @ts-ignore vuex-map-fields import
import { getField, updateField } from 'vuex-map-fields'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface IDisplayState {
  showHandles: Boolean
  mapZoom: Number
  storyBuilderPanel: NodeStoryKeys
  storyBorder: Boolean
  defaultStoryPathToEdit: string | null
  splashScreen: { showModal: boolean }
  switchingSecondaryApps: Boolean
  initialVisualisationType?: VisualisationType
}

@Module({ dynamic: true, store, name: 'display', namespaced: true })
export default class Display extends VuexModule implements IDisplayState {
  showHandles = false
  mapZoom = 0
  storyBuilderPanel: NodeStoryKeys = StoryKeys.SIDEBAR_TOP
  storyBorder = false
  defaultStoryPathToEdit: string | null = null
  switchingSecondaryApps: Boolean = false
  initialVisualisationType?: VisualisationType = undefined

  // modules: {
  //   splashScreen,
  // },
  @Mutation
  resetState() {
    this.showHandles = false
    this.mapZoom = 0
    this.storyBuilderPanel = StoryKeys.SIDEBAR_TOP
    this.storyBorder = false
    this.defaultStoryPathToEdit = null
    this.namespaced = true
    this.switchingSecondaryApps = false
    this.initialVisualisationType = undefined
  }

  @Mutation
  setShowHandles(payload: boolean) {
    this.showHandles = payload
  }

  @Mutation
  setMapZoom(payload: number) {
    this.mapZoom = payload
  }

  @Mutation
  updateStoryBuilderPanel(payload: NodeStoryKeys) {
    this.storyBuilderPanel = payload
  }

  @Mutation
  setStoryBorder(payload: boolean) {
    this.storyBorder = payload
  }

  @Mutation
  setDefaultStoryPathToEdit(payload: string | null) {
    this.defaultStoryPathToEdit = payload
  }

  @Mutation
  setSwitchingSecondaryApps(payload: boolean) {
    this.switchingSecondaryApps = payload
  }

  @Mutation
  setInitialVisualisationType(payload: VisualisationType) {
    this.initialVisualisationType = payload
  }

  get storyPath(): Path {
    const storyPath = typedStore.currentRoute.params.storyPath
    if (storyPath && Array.isArray(storyPath)) {
      // Handle array style path
      return new Path({ parts: storyPath })
    }
    return storyPath ? new Path({ path: storyPath }) : Path.root
  }

  get secondaryStoryPath(): Path {
    const storyPath = typedStore.currentRoute.params.secondaryStoryPath
    if (storyPath && Array.isArray(storyPath)) {
      // Handle array style path
      return new Path({ parts: storyPath })
    }
    return storyPath ? new Path({ path: storyPath }) : Path.root
  }

  get activeTab(): Tab | undefined {
    if (typedStore.primary.app.appLoaded) {
      const rootChild = this.storyPath.level1
      if (rootChild.startsWith(SECONDARY_APP_PATH_PREFIX)) {
        const secondaryRootChild = this.secondaryStoryPath.level1
        return (
          typedStore.primary.entities.tabs.allTabs.find(
            (x) => x.secondaryAppPath === rootChild && x.path === secondaryRootChild,
          ) ?? undefined
        )
      }
      return typedStore.primary.entities.tabs.allTabs.find((x) => x.path === rootChild) ?? undefined
    }

    return undefined
  }

  get isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }

  get isMobile() {
    const { clientWidth, clientHeight } = typedStore.public.size
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0) &&
      Math.max(clientWidth, clientHeight) < MOBILE_VIEWPORT_WIDTH
    )
  }

  get isLandscape() {
    return typedStore.public.size.clientWidth > typedStore.public.size.clientHeight
  }

  @Action({ rawError: true })
  updatePrimaryStoryPath(payload: string | Path) {
    const path = payload.toString()
    if (typedStore.primary.entities.images.isImageUploading === false) {
      const routeName = typedStore.currentRoute.name?.replace(SECONDARY_SUFFIX, '')
      router.push({
        name: routeName,
        // @ts-ignore router param array issue
        params: { storyPath: path.split('/') },
        query: typedStore.currentRoute.query,
      })
    }
  }

  @Action({ rawError: true })
  async updateSecondaryStoryPath(payload: {
    primaryStoryPath: string | Path
    secondaryStoryPath: string | Path
    mainpanel?: string
  }) {
    let routeName = typedStore.currentRoute.name ?? ''
    routeName += !routeName.endsWith(SECONDARY_SUFFIX) ? SECONDARY_SUFFIX : ''

    const location: Location = {
      name: routeName,
      params: {
        mainpanel: payload.mainpanel ?? typedStore.currentRoute.mainpanel,
        // @ts-ignore router param array issue
        storyPath: payload.primaryStoryPath.toString(),
        // @ts-ignore router param array issue
        secondaryStoryPath: payload.secondaryStoryPath.toString(),
      },
      query: typedStore.currentRoute.query,
    }

    await router.push(location)
  }

  // #region splashScreen.js
  splashScreen = {
    showModal: false,
  }

  @Mutation
  setShowSplashScreen(payload: boolean) {
    this.splashScreen.showModal = payload
  }

  get validSplashScreenDisplay() {
    const display = typedStore.primary.app.config.splashScreen?.display || SplashScreenDisplays.BOTH
    switch (display) {
      case SplashScreenDisplays.MOBILE:
        return this.isMobile
      case SplashScreenDisplays.DESKTOP:
        return !this.isMobile
      default:
        return true
    }
  }

  @Action({ rawError: true })
  showSplashScreen() {
    if (typedStore.primary.app.config.splashScreen?.enabled && this.validSplashScreenDisplay) {
      this.setShowSplashScreen(true)
    }
  }
  // #endregion splashScreen

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }

  @Action({ rawError: true })
  activateInitialTab(payload: { dataTabsOnly: boolean; primaryAppOnly: boolean }) {
    const { dataTabsOnly, primaryAppOnly } = payload
    const { visibleTabs } = typedStore.primary.entities.tabs
    const { isPrimaryApp } = typedStore.activeVisualisation

    const availableTabs =
      (isPrimaryApp || primaryAppOnly) && dataTabsOnly
        ? visibleTabs.filter((x) => x.nodeType === NodeTypes.DATA || x.nodeType === NodeTypes.ROOT)
        : visibleTabs

    const initialTab = availableTabs[0]
    const storyPath = initialTab?.path ?? Path.root

    if (isPrimaryApp || primaryAppOnly || !initialTab) {
      this.updatePrimaryStoryPath(storyPath)
    } else {
      this.updateSecondaryStoryPath({
        primaryStoryPath: initialTab.secondaryAppPath!,
        secondaryStoryPath: storyPath,
      })
    }
  }
}

export const DisplayModule = getModule(Display)
