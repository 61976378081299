<template>
  <Spinner v-if="isLoading" />
  <router-view v-else class="router-wrapper" />
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { DEFAULT_PAGE_TITLE } from '@/constants'
import { AppRanks } from '@/types'
import { growthBookService, settingsService } from '@/services'
import Spinner from '@/components/public/Spinner.vue'

const components = { Spinner }
// eslint-disable-next-line no-use-before-define
@Component<App>({
  components,
  watch: {
    currentAppRank(appRank) {
      this.$typedStore.activeVisualisation.setVisualisationAppRank(appRank)
    },
  },
})
export default class App extends Vue {
  isLoading = true

  metaInfo() {
    return {
      title: DEFAULT_PAGE_TITLE,
      meta: [
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: DEFAULT_PAGE_TITLE },
      ],
    }
  }

  async created() {
    try {
      await growthBookService.initialize({
        clientKey: process.env.VUE_APP_GROWTHBOOK_SDK_KEY ?? '',
      })
    } finally {
      this.isLoading = false
    }

    window.addEventListener('resize', () => {
      this.$typedStore.public.size.recalculate()
    })
  }

  mounted() {
    // Initialise values
    this.$typedStore.public.size.setMSIEversion()
    this.$typedStore.public.size.recalculate()
  }

  get isProduction() {
    return settingsService.isProd
  }

  get storyPath() {
    return this.$typedStore.public.display.storyPath
  }

  get primaryAppSlug() {
    return this.$typedStore.currentRoute.primaryAppSlug
  }

  get primaryAppId() {
    return this.$typedStore.currentRoute.primaryAppId
  }

  get isAuthenticated() {
    return this.$auth.isAuthenticated
  }

  get currentAppRank() {
    if (this.$typedStore.public.display.activeTab?.secondaryAppPath) {
      return AppRanks.SECONDARY
    }
    return AppRanks.PRIMARY
  }

  enableHotjar() {
    if (this.isProduction) {
      // Hotjar
      const h: any = window
      const o = document
      const t = 'https://static.hotjar.com/c/hotjar-'
      const j = '.js?sv='
      const a = o.getElementsByTagName('head')[0]
      const r: any = o.createElement('script')
      r.id = 'hotjar'

      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: 1446601, hjsv: 6 }
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    }
  }
}
</script>
